.content-filter-sidebar{
    position: fixed;
    height: calc(100% - 14rem);
    width: 60rem;
    margin-top: 14.2rem;
    right: -60rem;
    top: 0;
    background-color: var(--white-color);
    box-shadow: -0.3rem 0.3rem 0.5rem rgba(0,0,0,0.05);
    transform: translateX(0);
    transition: transform 0.5s;
    z-index: 2;
}
.content-filter-sidebar.open{
    transform: translateX(-100%);
}
.content-filter-sidebar .content-filter-sidebar-header{
    display:flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight:600;
    padding:1.4rem;
    border-top: 1px solid var(--line-color);
    border-bottom: 1px solid var(--line-color);
}
.content-filter-sidebar .content-filter-sidebar-body{
    padding:1.5rem;
}
.content-filter-sidebar-overlay{
    position:fixed;
    inset: 0;
    z-index: 1;
}
.modal-filter-panel{
    position: absolute;
    width: 100%;
    left:0;
    top: 0;
    background-color: var(--white-color);
    box-shadow: -0.3rem 0.3rem 0.5rem rgba(0,0,0,0.05);
    z-index: 5;
}
.modal-filter-panel .modal-filter-panel-header{
    display:flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight:600;
    padding:0.5rem 2rem;
    border-bottom: 1px solid var(--line-color);
}
.modal-filter-panel .modal-filter-panel-body{
    padding:2rem 2rem 0 2rem;
}