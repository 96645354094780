@media (max-width: 767px) {
    .col-xs-100{
        flex:0 0 100%;
        max-width:100%;
        padding: 0 1rem;
    }
    .col-xs-75{
        flex:0 0 75%;
        max-width:75%;
        padding: 0 1rem;
    }
    .col-xs-66{
        flex:0 0 66.6666%;
        max-width:66.6666%;
        padding: 0 1rem;
    }
    .col-xs-60{
        flex:0 0 60%;
        max-width:60%;
        padding: 0 1rem;
    }
    .col-xs-50{
        flex:0 0 50%;
        max-width:50%;
        padding: 0 1rem;
    }
    .col-xs-40{
        flex:0 0 40%;
        max-width:40%;
        padding: 0 1rem;
    }
    .col-xs-33{
        flex:0 0 33.3333%;
        max-width:33.3333%;
        padding: 0 1rem;
    }
    .col-xs-25{
        flex:0 0 25%;
        max-width:25%;
        padding: 0 1rem;
    }
    .col-xs-20{
        flex:0 0 20%;
        max-width:20%;
        padding: 0 1rem;
    }
    .col-xs-16{
        flex:0 0 16.6666%;
        max-width:16.6666%;
        padding: 0 1rem;
    }
    .col-xs-40{
        flex:0 0 40%;
        max-width:40%;
        padding: 0 1rem;
    }
}