.auth-panel{
    display:flex;
    align-items: center;
    background-image: url("../Media/Images/login_bg.webp");
    background-position: center center;
    background-repeat: no-repeat;
    height:90%;
    align-items: center;
    justify-content: center;
}
.auth-panel .card{
    width:50rem;
}