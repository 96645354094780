header{
    position:fixed;
    left:0;
    top:0;
    height:6rem;
    width:100%;
    padding:0 2rem 0 26.5rem;
    background-image: url("../Media/Images/header_bg.webp");
    background-position: 0 0;
    background-repeat: no-repeat;
    display:flex;
    align-items: center;
    padding-right:2rem;
    transition: all 0.5s;
    z-index: 3;
    background-repeat: repeat-x;
}
header .arrows{
    height:2rem;
    width:2rem;
    cursor:pointer;
    transition: padding 0.5s;
}
header.shrink{
    padding:0 2rem 0 6.6rem;
}
header.dev{
    top:5rem;
}
header.shrink .arrows{
    height:2rem;
    width:2rem;
    cursor:pointer;
    transform: rotateZ(180deg);
}
header nav{
    margin-left:auto;
}
header ul{
    display: flex;
    align-items: center;
}
header ul li img,
header ul li svg{
    height:2.6rem;
    width:2.6rem;
    margin-left:2rem;
    cursor:pointer;
    display: block;
}
header .choose-project{
    position:relative;
}
header .choose-project .project{
    border:1px solid var(--line-color);
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding:0.5rem 1rem;
    position:relative;
    cursor: pointer;
    background-color: var(--white-color);
}
header .choose-project img{
    margin-left:0;
    margin-right:1rem;
}
header .choose-project img.arrow{
    margin-right:0;
    margin-left:1rem;
    border-radius: 0;
    border:0x none;
    width:1.2rem;
    height:1.2rem;
    transform: rotateZ(135deg);
    position: relative;
    top:-0.2rem;
}
header .choose-project ul{
    position:absolute;
    top:4.9rem;
    left:0;
    background-color: var(--white-color);
    width:25rem;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 0 0.4rem 0.5rem 0 rgba(0,0,0,0.05);
    padding:0 1rem;
    display: block;
    z-index: 901;
}
header .choose-project ul li{
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--line-color);
    width: 100%;
}
header .choose-project ul li:last-child{
    border-bottom: 0px none;
}
header .choose-project ul li a{
    display: flex;
    align-items: center;
    padding:1rem 0;
    color: var(--text-color);
    text-decoration: none;
}
.sidebar{
    position:absolute;
    left:0;
    top:0;
    height:100%;
    width:26.5rem;
    background-image: url("../Media/Images/sidebar_bg.webp");
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: width 0.5s;
}
.sidebar.dev {
    top:5rem;
}
.sidebar.shrink{
    width:6.6rem;
}
.sidebar .logo{
    padding:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: padding 0.5s;
}
.sidebar .logo img{
    max-width:100%;
}
.sidebar nav{
    border-top:1px solid var(--line-color);
    margin:0 1rem;
}
.sidebar nav ul{
    margin:1rem 0;
}
.sidebar nav ul li{
    padding:0.25rem 0;
    position:relative;
    height: 45px;
    overflow: hidden;
}
.sidebar nav ul li.selected{
    height: auto;
    overflow: none;
}
.sidebar nav ul li.logout{
    margin-top:1rem;
    padding-top:1rem;
    border-top:1px solid var(--line-color);
}
.sidebar nav ul li a{
    font-size:1.6rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    height:4rem;
    color:var(--text-color);
    border-radius: 0.5rem;
    padding:0rem 1rem 0 1rem;
    position:relative;
}
.sidebar nav ul li a .arrow{
    position:absolute;
    width:0.8rem;
    height:0.8rem;
    top:50%;
    transform: translateY(-50%) rotateZ(45deg);
    right:0.25rem;
    z-index: 1;
    transition: transform 0.5s;
}
.sidebar nav ul li.active a.main,
.sidebar nav ul li a.main.active{
    background-color: var(--white-color);
    box-shadow: 0 0.4rem 0.5rem 0 rgba(0,0,0,0.05);
}
.sidebar nav ul li a.active{
    color:var(--blue-color);
}
.sidebar nav ul li.selected a .arrow{
    transform: translateY(-50%) rotateZ(135deg);
}
.sidebar nav ul li a:hover{
    color:var(--blue-color);
}
.sidebar nav ul li a img,
.sidebar nav ul li a svg{
    margin-right:1.5rem;
    height:2.6rem;
    width:2.6rem;
}
.sidebar nav ul li .badge{
    position:absolute;
    right: 3.5rem;
    bottom: 0.7rem;
}
.sidebar nav ul li ul li .badge{
    right: 0.5rem;
    bottom: 0.4rem;
}
.sidebar.shrink nav ul li .badge{
    right: auto;
    left: 3rem;
    display: block;
    bottom: 0.4rem;
    padding: 0 0.6rem;
}
.sidebar nav ul li ul{
    padding-left:3.5rem;
}
.sidebar nav ul li ul li{
    height:3.5rem;
    position:relative;
    padding:0;
    height: auto;
    overflow: visible;
}
.sidebar nav ul li ul li a{
    height:3.5rem;
    padding:0 0 0 1.6rem;
}
.sidebar nav ul li ul li.selected a{
    background-color: transparent;
    box-shadow: none;
}
.sidebar nav ul li ul li:before{
    content:" ";
    position: absolute;
    left:-1.5rem;
    top:50%;
    transform: translateY(-50%);
    width:0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
    background-color: var(--light-gray-color-2);
}
.sidebar.shrink nav {
    margin: 0 0 0 1rem;
}
.sidebar.shrink ul li a span,
.sidebar.shrink ul li a .arrow{
    display:none;
}
.sidebar.shrink nav ul li a img,
.sidebar.shrink nav ul li a svg{
    margin:0;
}
.sidebar.shrink nav ul li a{
    border-radius: 0.5rem 0 0 0.5rem;
    padding: 0 2rem 0 1rem;
}
.sidebar.shrink div.logo{
    padding:1.5rem;
}
.sidebar.shrink ul li ul{
    position:absolute;
    right:-20rem;
    top:0.2rem;
    width: 20rem;
    padding:0 1rem;
    margin:0;
    border-top:1px solid var(--line-color);
    border-right:1px solid var(--line-color);
    border-bottom:1px solid var(--line-color);
    border-radius: 0 1rem 1rem 0;
    box-shadow: 0 0 0.5rem 0 rgba(0,0,0,0.05);
    background-color: var(--white-color);
}
.sidebar.shrink nav ul li ul li:before {
    content: none;
}
.sidebar.shrink nav ul li.active a.main, 
.sidebar.shrink nav ul li a.main.active{
    box-shadow: none;
}

