.calendar{
    display: inline-block;
    border-radius: 1rem;
    border:1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.calendar.floating{
    width: 100%;
}
.calendar .months{
    display:flex;
    border-bottom:1px solid #ddd;
    padding:1rem;
    align-items: center;
}
.calendar .months .month{
    font-size:1.4rem;
    flex:1;
    text-align: center;
    text-transform: capitalize;
    color: var(--blue-color);
}
.calendar .months .arrow{
    width:2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.calendar .months .arrow svg{
    width: 1.5rem;
    cursor:pointer;
}
.calendar .months svg.right{
    transform: rotateZ(180deg);
}
.calendar .days{
    display:flex;
    padding:1rem;
    flex-direction: column;
}
.calendar .days-row{
    display:flex;
    align-items: center;
}
.calendar .day{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:3.4rem;
    min-height:3.4rem;
    font-size:1.4rem;
    padding:0.5rem;
}
.calendar.floating .day{
    width: calc(100% / 7);
    min-width:auto;
    min-height:auto;
}
.calendar .days .days-row:not(.names) .day:not(.in-past):not(.blocked):not(.empty){
    cursor:pointer;
}
.calendar .days .days-row:not(.names) .day.clickable{
    cursor:pointer;
}

.calendar .days .days-row:not(.names) .day:not(.blocked):not(.in-past):not(.selected):not(.empty):hover{
    background-color: #eee;
}
.calendar .days .days-row:not(.names) .day.selected:not(.blocked):hover{
    background-color: var(--blue-color);
}
.calendar .days .day{
    border:1px solid var(--white-color);
    border-radius:1rem;
}
.calendar .days .day.in-past,
.calendar .legend .day.in-past{
    color:#b5b5b5;
}
.calendar .days .day.selected,
.calendar .legend .day.selected{
    background-color: var(--blue-color);
    color:#fff;
}
.calendar .days .day.blocked,
.calendar .legend .day.blocked{
    background-color: var(--brown-color);
    color:#fff;
}

.calendar .legend{
    border-top:1px solid #ddd;
    padding:1rem;
}
.calendar .legend .day{
    min-width:1.7rem;
    min-height:1.7rem;
    border-radius:0.5rem;
    width:auto;
}
.calendar.with-green-buttons .days .days-row:not(.names) .day:not(.blocked):not(.in-past):not(.empty):not(.selected){
    cursor:pointer;
    background-color: var(--green-bg);
    color:#fff;
}
.calendar.with-green-buttons .days .days-row:not(.names) .day:not(.blocked):not(.in-past):not(.selected):not(.empty):hover{
    background-color: var(--green-bg-light);
}
.datepicker{
    position:relative;
}
.datepicker .pop-up-calendar{
    position:absolute;
    top:4rem;
    left:0;
    z-index: 1000;
}
.datepicker .pop-up-calendar-overlay{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 999;
}

/* časy */
.calendar-time{
    border-radius: 1rem;
    background-color: var(--green-bg);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom:1rem;
    padding:1rem;
    cursor: pointer;
    color: #fff;
    font-size:1.4rem;
}
.calendar-time:not(.blocked):not(.selected):hover{
    background-color: var(--green-bg-light);
}
.calendar-time.blocked{
    background-color: var(--brown-color);
    color: #fff;
    cursor: default;
}
.calendar-time.selected{
    background-color: var(--blue-color);
    color:var(--white-color);
}